import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {DatePipe} from '@angular/common';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute, RoutesRecognized} from '@angular/router';
import {DataService} from '../../assets/services/data.service';
import {environment} from '../../environments/environment';
import {ToastService} from '../../assets/services/toast.service';
import {Router} from '@angular/router';
import * as moment from 'moment';
import {MatStepper} from '@angular/material';

@Component({
  selector: 'app-activation',
  templateUrl: './activation.component.html',
  styleUrls: ['./activation.component.scss']
})
export class ActivationComponent implements OnInit, OnDestroy {
  submitted = false;
  plans: any[] = [];
  selectedPlan: { name?: string; warranty_type?: number; duration?: number; isOffer?: boolean } = null;
  fileToUpload: File;
  image: any;
  serialLoading = false;
  token: any;
  referenceKey: string;
  active = '';
  invoiceUpload = false;
  isVc = false;
  isTv = false;
  public stateList = {};
  public cityList: any = {};
  public stateArray = [];
  public cityArray = [];
  public pincode = [];
  public stateCityMapping: any = {};
  public desiredDevice: any;
  public scannerEnabled = true;
  public completed = false;
  warranty: any = {
    serialNumber: '',
    name: '',
    address: '',
    city: '',
    state: '',
    pin: '',
    phone: '',
    email: '',
    activation_code: '',
    isActivation_code: '',
    tvSize: null,
    premium: 0,
    purchasedate: '',
    seller: ''
  };

  productModel: any = {
    707: 'MI LED TV (55")',
    706: 'MI LED TV (49")',
    705: 'MI LED TV (43")',
    704: 'MI LED TV (32")',
    715: 'MI LED TV 4X (65")',
    714: 'MI LED TV 4X (50")',
    713: 'MI LED TV 4X (43")',
    712: 'MI LED TV 4A (40")',
    790: 'Mi TV Horizon (32")',
    791: 'Mi TV Horizon (43")',
  };

  isPurifier = false;
  serialNoCheckRes: any = {};

  constructor(private request: DataService, public toast: ToastService, private router: Router,
              private datePipe: DatePipe,
              private route: ActivatedRoute, private cookie: CookieService) {
  }

  @ViewChild('stepper') stepper: MatStepper;

  ngOnInit() {
    this.ngOnDestroy();
    this.route.params.subscribe(params => {
      if (params.id) {
        this.token = params.id;
        this.request.stateCityListing()
          .subscribe((response) => {
            this.stateCityMapping = response.data;
            for (const id of Object.keys(this.stateCityMapping)) {
              this.stateArray.push({
                id,
                name: this.stateCityMapping[id].name,
              });
            }
          });
      }
    });
  }

  onStateChange(stateId) {
    this.cityArray = [];
    this.pincode = [];
    if (stateId) {
      this.cityList = this.stateCityMapping[stateId].cities;
      // Converted mapping to array for sorting in ng-options
      if (this.cityList !== undefined) {
        for (const id of Object.keys(this.cityList)) {
          this.cityArray.push({
            id,
            name: this.cityList[id].name,
          });
        }
      }
    }
    // Clear city selection when state is changed
  }

  onCityChange(stateId, cityId) {
    this.pincode = [];
    if (stateId && cityId) {
      this.pincode = this.stateCityMapping[stateId].cities[cityId].pincodes;
    }
  }

  camerasFoundHandler(event) {
    this.desiredDevice = event.length > 1 ? event[1] : event[0];
  }

  scanSuccessHandler(event) {
    this.warranty.serialNumber = event;
    this.scannerEnabled = false;
  }

  getCategoryBoolean(res) {
    if (res.isMobile) {
      return 'isMobile';
    } else if (res.isLaptop) {
      return 'isLaptop';
    } else if (res.isPurifier) {
      return 'isPurifier';
    } else if (res.isVacuumCleaner) {
      return 'isVacuumCleaner';
    } else {
      return 'isTV';
    }
  }

  onPlanChange() {
    console.log();
    console.warn('inside plan change');
    console.warn(this.selectedPlan);
    this.warranty.premium = '';
    this.warranty.offeredPremium = null;
    if (this.selectedPlan && this.selectedPlan.warranty_type) {
      console.warn('got here');
      // this.calculateDate();
      const param = {
        price: this.serialNoCheckRes.price,
        category: this.serialNoCheckRes.category,
        duration: this.selectedPlan.duration,
        business: environment.xiaomi_store,
        warrantytype: this.selectedPlan.warranty_type,
        isOffer: !!this.selectedPlan.isOffer ? 1 : 0
      };
      this.getPremium(param);
    }
  }

  fetchPlans(userType, category) {
    const obj: any = {};
    if (userType && userType !== 'null') {
      obj.user_type = userType;
    }
    if (category) {
      obj.category = category;
    }
    console.warn(obj);
    this.request.fetchPlansFromUserTypeAndCategory(obj, this.token).subscribe((response) => {
      if (response.status === 200) {
        this.plans = response.data.data;
        if (this.plans.length === 1) {
          this.selectedPlan = this.plans[0];
          this.onPlanChange();

        }
        this.serialLoading = false;
        setTimeout(() => {
          this.stepper.selectedIndex = 1;
        }, 0);
      }
    });
  }

  handleEnter(event: KeyboardEvent) {
    console.log(event);
    if (event.key === 'Enter') {
      this.processSerialNumber();
    }
  }

  processSerialNumber() {
    this.completed = true;

    if (this.warranty.serialNumber) {
      this.serialLoading = true;
      const param = {
        serialnumber: this.warranty.serialNumber
      };
      this.request.serialNumberCheck(param, this.token).subscribe((res: any) => {
        this.serialLoading = false;
        this.serialNoCheckRes = res.data;
        this.isPurifier = res.data.isPurifier;
        this.isVc = res.data.isVacuumCleaner;
        this.isTv = res.data.isTV;
        if (!this.isVc && !this.isPurifier && !this.isTv) {
          this.toast.showToast({type: 'error', body: 'This product category is not allowed!'});
          return;
        }

        this.request.fetchOtherDocuments({code: 'mi-parent-category'}, this.token).subscribe((response) => {
          if (response.status === 200 && response.data) {
            this.fetchPlans(7, response.data[this.getCategoryBoolean(res.data)]);

          }
        });

        // if (this.serialNoCheckRes.isPurifier || this.isVc) {
        //   const purifierParam = {
        //     price: this.serialNoCheckRes.price,
        //     category: this.serialNoCheckRes.category,
        //     duration: 12,
        //     business: environment.xiaomi_store
        //   };
        //   this.getPremium(purifierParam);
        // } else {
        //   this.getModelName(this.serialNoCheckRes.skuName);
        // }
      }, (err) => {
        this.serialLoading = false;
        if (err.status === 401) {
          this.toast.showToast({
            type: 'error',
            title: 'You are not authorised to view this',
            body: ''
          });
        } else {
          this.toast.showToast({
            type: 'error',
            title: 'Serial number is invalid',
            body: ''
          });
        }
      });
    }
  }


  uploadInvoice(files: FileList) {
    this.image = '';
    this.invoiceUpload = true;
    this.fileToUpload = files.item(0);
    const fileReader = new FileReader();
    const formData = new FormData();
    if (files.length > 0) {
      formData.append('file', this.fileToUpload);
      this.request.mediaUpload(this.referenceKey, formData, this.token).subscribe((response: any) => {
        this.invoiceUpload = false;
        if (response.data && response.data.referenceid) {
          this.referenceKey = response.data.referenceid;
        }
        this.toast.showToast({
          type: 'success',
          title: '',
          body: 'Image Uploaded Successfully'
        });
        fileReader.onload = (e: any) => {
          this.image = e.target.result;
        };
        fileReader.readAsDataURL(this.fileToUpload);
      }, (error) => {
        this.invoiceUpload = false;
      });
    } else {
      this.invoiceUpload = false;
    }
  }

  getdate(days) {
    return {
      year: parseInt(moment().add(days, 'days').format('YYYY'), 10),
      month: parseInt(moment().add(days, 'days').format('MM'), 10),
      day: parseInt(moment().add(days, 'days').format('DD'), 10),
    };
  }


  getPremium(param) {
    this.request.getPremium(param, this.token).subscribe((response) => {
      this.warranty.premium = response.data.premium;
    });
  }

  submitwarranty() {
    this.submitted = true;
    const payload: any = {
      customer: {
        address: this.warranty.address,
        city: parseInt(this.warranty.city, 10),
        email: this.warranty.email,
        name: this.warranty.name,
        phone: this.warranty.phone,
        pincode: parseInt(this.warranty.pin, 10),
        state: parseInt(this.warranty.state, 10)
      },
      premium: this.warranty.premium,
      store: environment.xiaomi_store,
      product: {
        brand: 'Xiaomi',
        brand_warranty_duration: 12,
        invoiceimage: this.referenceKey,
        invoicenumber: 'temporary123',
        purchasedate: this.warranty.purchasedate.year + '-' + this.warranty.purchasedate.month + '-' +
          this.warranty.purchasedate.day,
        serialnumber: this.warranty.serialNumber
      },
      seller: this.warranty.seller
    };
    payload.product.category = this.serialNoCheckRes.category;
    payload.product.model = this.serialNoCheckRes.skuName;
    if (this.selectedPlan.duration) {
      payload.duration = this.selectedPlan.duration;
    } else {
      console.warn('not got duration in selected plan continuing with fallback 12');
      payload.product.brand_warranty_duration = 12;
      payload.duration = 12;
    }
    if (this.serialNoCheckRes && this.serialNoCheckRes.price) {
      payload.product.price = this.serialNoCheckRes.price;
    } else {
      console.error('not got price in checkSerial Response continuing with fallback 0');
      payload.product.price = 0;
    }

    this.request.kitSubmission(payload, this.token).subscribe((response: any) => {
      this.submitted = false;
      if (environment.REDIRECTION_URL && response.data.id) {
        location.href = environment.REDIRECTION_URL + '?id=' + response.data.id;
      }
    }, (error) => {
      this.submitted = false;
    });
  }

  ngOnDestroy() {
    this.warranty = {
      serialNumber: '',
      name: '',
      address: '',
      city: '',
      state: '',
      pin: '',
      phone: '',
      email: '',
      activation_code: '',
      isActivation_code: '',
      tvSize: null,
      premium: 0,
      purchasedate: '',
      seller: ''
    };
    this.serialLoading = false;
    this.image = '';
  }
}
