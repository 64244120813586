import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ToasterModule, ToasterService } from "angular2-toaster";
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import {
  MatInputModule, MatButtonModule, MatSelectModule, MatCardModule, MatTabsModule, MatDialogModule, MatIconModule, MatListModule,
  MatMenuModule, MatCheckboxModule, MatRadioModule
} from "@angular/material";
import {
  NgbDropdownModule,
  NgbModule,
  NgbPopover,
  NgbTabsetModule,
  NgbDatepicker,
  NgbDatepickerModule,
  NgbDatepickerConfig,
  NgbDateParserFormatter,
  NgbDateAdapter
} from '@ng-bootstrap/ng-bootstrap';
import { MatStepperModule } from '@angular/material/stepper';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotFoundComponent} from './not-found/not-found.component';
import {FormsModule} from '@angular/forms';
import {ToastService} from '../assets/services/toast.service';
import {CookieService} from 'ngx-cookie-service';
import {OrderModule} from 'ngx-order-pipe';
import {DatePipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ActivationComponent } from './activation/activation.component';

@NgModule({
  declarations: [
    AppComponent, NotFoundComponent, ActivationComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    ToasterModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule, MatListModule,
    MatMenuModule,
    MatCardModule, MatCheckboxModule, MatRadioModule,
    MatTabsModule, MatDialogModule, OrderModule,
    MatStepperModule,
    ZXingScannerModule,
    NgbModule
  ],
  providers: [ToastService, ToasterService, CookieService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
