import { Injectable } from "@angular/core";
import {
  ToasterService,
  ToasterConfig,
  Toast,
  BodyOutputType
} from 'angular2-toaster';
import 'style-loader!angular2-toaster/toaster.css';

@Injectable({
  providedIn: "root"
})
export class ToastService {
  config: ToasterConfig;
  constructor(private toasterService: ToasterService) {}

  showToast(data) {
    this.config = new ToasterConfig({
      positionClass: "toast-top-right",
      timeout: 5000,
      newestOnTop: true,
      tapToDismiss: true,
      preventDuplicates: false,
      animation: "fade",
      limit: 5
    });
    const toast: Toast = {
      type: data.type,
      title: data.title,
      body: data.body,
      timeout: 5000,
      showCloseButton: true,
      bodyOutputType: BodyOutputType.TrustedHtml
    };
    this.toasterService.popAsync(toast);
  }
}
