// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  BASE_URL: 'https://zopperwarranty-pub.zopper.info',
  URL_MAPPING: {
    sales: '//sales.stg.zopperext',
    foundation: 'https://apis.zopper.info'
  },
  IMAGE_BASE_URL: 'https://apis.zopper.info/media/',
  REDIRECTION_URL: 'http://staging.mybillz.io/auth/zopper',
  xiaomi_store: 246219
};

// export const environment = {
//   production: true,
//   BASE_URL: 'https://zopperwarranty-pub.zopper.com:86',
//   URL_MAPPING : {
//     sales: 'https://salesapi.zopper.com',
//     foundation: 'https://apis.zopper.com'
//   },
//   IMAGE_BASE_URL: 'https://apis.zopper.com/media/',
//   REDIRECTION_URL: 'https://mybillz.io/auth/zopper',
//   xiaomi_store: 306648
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
