import {Injectable} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {ApiRequestService} from './api-request.service';
import {HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  token: string;

  constructor(
    private apiRequest: ApiRequestService,
    private cookie: CookieService
  ) {
    this.token = this.cookie.get('sessionID');
  }

  isAuthenticated() {
    return this.cookie.get('sessionID');
  }

  formatDate(date) {
    let month = date.month.toString(),
      day = date.day.toString(),
      year = date.year.toString();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  catalogCategories(param, token) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token
    });
    return this.apiRequest.get({
      url: '/mapping/foundation/catalog/categories',
      headers: headers,
      params: param,
      showError: false
    });
  }

  getPremium(param, token) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token
    });
    return this.apiRequest.get({
      url: '/zopperassure/premium',
      headers: headers,
      params: param
    });
  }

  mediaUpload(key, param, token) {
    const headers = new HttpHeaders({
      'Authorization': 'Token ' + token,
    });
    if (key) {
      return this.apiRequest.post({
        url: '/mapping/foundation/media/' + key,
        headers: headers,
        data: param
      });
    } else {
      return this.apiRequest.post({
        url: '/mapping/foundation/media',
        headers: headers,
        data: param
      });
    }
  }

  fetchOtherDocuments(param, token): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token
    });
    return this.apiRequest.get({
      url: '/zopper/documents',
      params: param,
      headers
    });
  }

  fetchPlansFromUserTypeAndCategory(params: any, token: string) {
    const endpoint = '/mapping/foundation/accounts/plantype/listing';
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token
    });
    return this.apiRequest.get({
      url: endpoint,
      headers,
      params
    });
  }

  stateCityListing() {
    return this.apiRequest.get({
      url: '/mapping/foundation/locations',
    });
  }

  kitSubmission(payload, token) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token
    });
    return this.apiRequest.post({
      url: '/zopperassure/kit',
      headers: headers,
      data: payload
    });
  }

  serialNumberCheck(params, token) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token
    });
    return this.apiRequest.get({
      url: '/partner/serialnumber/check',
      headers,
      params,
      showError: false
    });
  }

  kitActivate(payload, token) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token
    });
    return this.apiRequest.post({
      url: '/zopperassure/kit/self/activate',
      headers: headers,
      data: payload
    });
  }

}
